.form_modified {
    height: 30px;
    width: 400px;
    background: white;
    border: 1px rgba(0, 0, 0, 0.212) solid;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    outline:none;
    font-weight: bold;
    font-family: 'Quicksand';
    margin-bottom: 10px;
    margin-top: 5vh;

}

.confirmer {
    margin-top: 5vh;
    width: 11vw;
    height: 4vh;
}

@media only screen and (max-width: 760px) {
    .confirmer {
        width: 100px;
        height: 50px;
    }

}
