.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }


  .cadastrando_ideia_wrapper {
    display: flex;
    justify-content: center;
  }

  .cadastrando_ideia {
    background-color: rgb(221, 197, 60);
    color: rgb(0, 0, 0);
    width: 100px;
    height: 40px;
    margin-top: 2vw;
    border: 1px solid rgb(4, 0, 255);
    border-radius: 6px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 13px;
  }

  .cadastrando_ideia:hover {
    background-color: rgba(221, 197, 60, 0.679);
    cursor: pointer;
    color: rgb(0, 0, 0);
    width: 100px;
    height: 40px;
    margin-top: 2vw;
    border: 1px solid rgb(4, 0, 255);
    border-radius: 6px;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    z-index: 0;
    width: 25vw;
  }

  .cards__item__start {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    z-index: 0;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  .centertext {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

  }
  .select_centralizer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

  }
  .seletor_master {
    width: 200px;
    

  }


  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .button_preenchedor {
    display: flex;
    background-color: rgba(125, 207, 213, 0.59);
    width: 100px;
    height: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    justify-self: center;
    border: 2px solid black;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .button_preenchedor:hover {
    background-color: rgb(125, 207, 213);
    cursor: pointer;
  }

  .button_preenchedor_top {
    display: flex;
    background-color: rgba(125, 207, 213, 0.59);
    width: 100px;
    height: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    justify-self: center;
    border: 2px solid black;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: -10px;

  }

  .button_preenchedor_top:hover {
    background-color: rgb(125, 207, 213);
    cursor: pointer;

  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .carregarmaisfonte {
    font-size: 16px;
    font-family: 'Roboto';
  }

  .button_master_footer {
    display: flex;
    flex-direction: row;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
    .cards__item__text {
      color: #252e48;
      line-height: 24px;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;

    }

    .cards__item {
      display: flex;
      flex: 1;
      margin: 0 1rem;
      border-radius: 10px;
      z-index: 0;
      width: 50vw;
      margin-bottom: 40px;
    }

    
   
  }

  @media only screen and (max-width: 500px) {
    .cards__item {
      margin-bottom: 2rem;

    }

    .cards__item__info {
      padding: 20px 5px 30px;
    }

    .cards__item {
      display: flex;
      flex: 1;
      margin: 0 1rem;
      border-radius: 10px;
      z-index: 0;
      width: 50vw;
      margin-bottom: 40px;
    }

    .cards__item__text {
      color: #252e48;
      font-size:11px;
      text-align: center;
      width: 100%;
      line-height: 15px;
    }

    .cards__item__pic-wrap::after {
      content: attr(data-category);
      position: absolute;
      bottom: 0;
      margin-left: 10px;
      padding: 6px 8px;
      max-width: calc((100%) - 60px);
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      background-color: #1f98f4;
      box-sizing: border-box;
    }

    
  
   
  }
  
  