@import url(https://fonts.googleapis.com/css2?family=Cabin&family=Dosis:wght@500&family=Quicksand:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    margin-right: 12px;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1700px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .logo {
      width: 3vw;
      height: 3vw;
      margin-top: 0.3vw;
      margin-left: 0.5vw;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
    cursor: pointer;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;

    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
      cursor: pointer;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.loading_Error {
  margin-left: 10px;
  margin-top: 10px;
}
.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }


  .cadastrando_ideia_wrapper {
    display: flex;
    justify-content: center;
  }

  .cadastrando_ideia {
    background-color: rgb(221, 197, 60);
    color: rgb(0, 0, 0);
    width: 100px;
    height: 40px;
    margin-top: 2vw;
    border: 1px solid rgb(4, 0, 255);
    border-radius: 6px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 13px;
  }

  .cadastrando_ideia:hover {
    background-color: rgba(221, 197, 60, 0.679);
    cursor: pointer;
    color: rgb(0, 0, 0);
    width: 100px;
    height: 40px;
    margin-top: 2vw;
    border: 1px solid rgb(4, 0, 255);
    border-radius: 6px;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
    z-index: 0;
    width: 25vw;
  }

  .cards__item__start {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
    z-index: 0;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  .centertext {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

  }
  .select_centralizer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

  }
  .seletor_master {
    width: 200px;
    

  }


  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .button_preenchedor {
    display: flex;
    background-color: rgba(125, 207, 213, 0.59);
    width: 100px;
    height: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    justify-self: center;
    border: 2px solid black;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .button_preenchedor:hover {
    background-color: rgb(125, 207, 213);
    cursor: pointer;
  }

  .button_preenchedor_top {
    display: flex;
    background-color: rgba(125, 207, 213, 0.59);
    width: 100px;
    height: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    justify-self: center;
    border: 2px solid black;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: -10px;

  }

  .button_preenchedor_top:hover {
    background-color: rgb(125, 207, 213);
    cursor: pointer;

  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .carregarmaisfonte {
    font-size: 16px;
    font-family: 'Roboto';
  }

  .button_master_footer {
    display: flex;
    flex-direction: row;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
    .cards__item__text {
      color: #252e48;
      line-height: 24px;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;

    }

    .cards__item {
      display: flex;
      flex: 1 1;
      margin: 0 1rem;
      border-radius: 10px;
      z-index: 0;
      width: 50vw;
      margin-bottom: 40px;
    }

    
   
  }

  @media only screen and (max-width: 500px) {
    .cards__item {
      margin-bottom: 2rem;

    }

    .cards__item__info {
      padding: 20px 5px 30px;
    }

    .cards__item {
      display: flex;
      flex: 1 1;
      margin: 0 1rem;
      border-radius: 10px;
      z-index: 0;
      width: 50vw;
      margin-bottom: 40px;
    }

    .cards__item__text {
      color: #252e48;
      font-size:11px;
      text-align: center;
      width: 100%;
      line-height: 15px;
    }

    .cards__item__pic-wrap::after {
      content: attr(data-category);
      position: absolute;
      bottom: 0;
      margin-left: 10px;
      padding: 6px 8px;
      max-width: calc((100%) - 60px);
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      background-color: #1f98f4;
      box-sizing: border-box;
    }

    
  
   
  }
  
  
.cardBackgrounder {
    background-color: rgba(0, 0, 0, 0.597);
    display: flex;
    position: absolute;
    align-self: flex-start;
    display: none;
    position: fixed;
    z-index: 999;
}

.genDiv {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0vw;
    top: 0vw;
    right: 0vw;
    left: 0vw;
    z-index: 10000;


}

.modal {
    position: fixed;
    bottom: 0vw;
    top: 0vw;
    right: 0vw;
    left: 0vw;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.672);
    
  }
  
  .modal_content {
    background-color: white;
    position: absolute;
    width: 750px;
    height: 400px;
    padding: 20px;
    border-radius: 15px;
    border: 2px solid rgb(0, 0, 0);
    z-index: 1000;
    display: flex;
    padding-right: 0;
  }

  .imagedescription {
      display: flex;
      flex-direction: column;
      width: 60%;
      height: 100%;
      border-radius: 1vw;

  }

  .butoneiro {
      width: 200px;
      height: 40px;
      font-family: 'Roboto';
      margin-top: 1.8vh;
      border: 1px solid black;
      border-radius: 3px;
      text-align: center;
      color: black;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5vh;

      background-color: rgba(194, 194, 194, 0.816);
  }

  .butoneiro:hover {
    background-color: rgb(232, 232, 232);
    cursor: pointer;
}

.project_description_master {
    overflow: hidden;
    width: 190%;
    margin-left: 0.3vw;
    margin-top: 0.5vh;
}

  .fount {
      margin-top: 1vh;
      font-size: 15px;
      font-family: 'Roboto'
  }

  .maxfount {
      width: 155%;
      overflow: hidden;


  }

  .campi_ensino {
    width: 30%;
    height: 16%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-self: flex-end;
    justify-content: center;
  }

  .campi_ensino_text {
    font-size: 14px;
    display: flex;
  }


  .exit {
      display: flex;
      position: absolute;
      width: 3%;
      height: 5%;
      margin-left: 91%;
  }

  .exit:hover {
    background-color: rgba(160, 160, 167, 0.241);
    cursor: pointer;
}

  .fount_part {
    margin-top: 1vh;
    font-size: 12px;
    font-family: 'Roboto';
    width: 100%;
    overflow: hidden;
}

  .popupimage {
      width: 102%;
      height: 62%;
      border-radius: 1vw;
  }

  .info_column {
      display: flex;
      flex-direction: column;
      margin-left: 3%;
      margin-top: 2%;
      height: 100%;
      width: 70%;

  }
  .project_title {
      font-size: 13px;
      margin-top: 4%;
      width: 130%;

  }

  .category-color {
    background-color: rgb(123, 114, 114);
    height: 15%;
    display: flex;
    align-self: flex-end;
    border-radius: 5px 0px 0px 5px;
    border: 1px 1px 1px 0px solid black;
    align-items: center;
    justify-content: center;

  }

  .category-text {
      color: white;
      font-family: 'Roboto';
      text-align: center;
  }

  .instagram {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .facebook {
    width: 25px;
    height: 25px;
    margin-left: 0px;
  }


  .twitter {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .social_media_div {
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 73%;
      margin-top: -0.4%;

  }

  .social_media_div:hover {
    cursor: pointer;

}

@media only screen and (max-width: 760px) {
    .modal_content {
        background-color: white;
        position: absolute;
        width: 400px;
        height: 600px;
        padding: 10px;
        border-radius: 15px;
        border: 2px solid rgb(0, 0, 0);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        padding-right: 0;
      }

    
    .campi_ensino {
      width: 35%;
      height: 30%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-self: flex-end;
      justify-content: center;
    }
 

    .imagedescription {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60%;
        border-radius: 1vw;
        margin-top: -20px;
        text-align: center;
        margin-left: 0vw;
  
    }

    .info_column {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin-left: 0vw;
  
    }

    .genDiv {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        bottom: 0vw;
        top: 0vw;
        right: 0vw;
        left: 0vw;
        z-index: 10000;
    
    
    }

    .popupimage {
        width: 50%;
        height: 70%;
        border-radius: 1vw;
    }

    .project_description_master {
        overflow: hidden;
        width: 100%;
        font-size: 13px;
        margin-left: 0.3vw;
        margin-top: 0.5vh;
    }

    .maxfount {
        width: 100%;
        overflow: hidden;
  
  
    }

    .social_media_div {
        display: flex;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        margin-top: 137.5%;
  
    }

    .instagram {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
      }
    
      .facebook {
        width: 30px;
        height: 30px;
      }
    
    
      .twitter {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
    
      }
    
    .project_title {
      font-size: 12px;
      margin-top: 4%;
      width: 100%;
    }

      .exit {
        display: flex;
        position: absolute;
        width: 25px;
        height: 25px;
        margin-left: 88.5%;
    }

    .category-text {
      color: white;
      font-family: 'Roboto';
      font-size: 13px;
      text-align: center;
    } 
    .category-color {
      background-color: rgb(123, 114, 114);
      height: 15%;
      display: flex;
      align-self: flex-end;
      border-radius: 5px 0px 0px 5px;
      border: 1px 1px 1px 0px solid black;
      align-items: center;
      justify-content: center;
  
    }

    .butoneiro {
      width: 150px;
      height: 30px;
      font-family: 'Roboto';
      margin-top: 1.8vh;
      border: 1px solid black;
      border-radius: 3px;
      text-align: center;
      color: black;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5vh;

      background-color: rgba(194, 194, 194, 0.816);
  }

    .testador {
      font-size: 10px;



    }

    .project_description_master {
      overflow: hidden;
      width: 100%;
      height: 50px;
      font-size: 13px;
      margin-left: 0.3vw;
      margin-top: 0.5vh;
  }


    
}

@media only screen and (max-width: 500px) {
    .modal_content {
        background-color: white;
        position: absolute;
        width: 300px;
        height: 600px;
        padding: 10px;
        border-radius: 15px;
        border: 2px solid rgb(0, 0, 0);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        padding-right: 0;
      }


      

      .campi_ensino {
        width: 35%;
        height: 20%;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-self: flex-end;
        justify-content: center;
      }
    
      .campi_ensino_text {
        font-size: 12px;
        display: flex;
      }


    .imagedescription {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 400px;
        border-radius: 1vw;
        margin-top: -20px;
        text-align: center;
        margin-left: 0vw;
  
    }

    .info_column {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin-left: 0vw;
  
    }

    .genDiv {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        bottom: 0vw;
        top: 0vw;
        right: 0vw;
        left: 0vw;
        z-index: 10000;
    
    
    }

    .popupimage {
        width: 200px;
        height: 100px;
        border-radius: 1vw;
    }

    .project_description_master {
        display: flex;
        height: 70px;
        font-size: 13px;
        margin-left: 0.3vw;
    }

    .maxfount {
        width: 100%;
        overflow: hidden;
  
  
    }

    .social_media_div {
        display: flex;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 33%;
        margin-top: 130%;
  
    }

    .instagram {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
      }
    
      .facebook {
        width: 30px;
        height: 30px;
      }
    
    
      .twitter {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
    
      }

      .exit {
        display: flex;
        position: absolute;
        width: 20px;
        height: 20px;
        margin-left: 86.5%;
    }

    .category-text {
      color: white;
      font-family: 'Roboto';
      font-size: 13px;
      text-align: center;
    } 
    .category-color {
      background-color: rgb(123, 114, 114);
      height: 15%;
      display: flex;
      align-self: flex-end;
      border-radius: 5px 0px 0px 5px;
      border: 1px 1px 1px 0px solid black;
      align-items: center;
      justify-content: center;
  
    }
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .hero-container {
    background: url('https://firebasestorage.googleapis.com/v0/b/projetointegra-5e4cf.appspot.com/o/Teste.JPG?alt=media&token=edb39163-f0be-46be-b93f-c9cbab831077') center center/cover no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }


  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }

    .hero-container > p {
      text-align: center;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 20px;
      text-align: center;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  
.informacoes {
    display: flex;
    flex-direction: column;
    background-color: rgb(33, 33, 33);
    width: 100%;
    height: 1700px;
    align-items: center;

    
}

.info_title {
    color: white;
    font-size: 40px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 50px;

    
}

.info_info {
    color: white;
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 300;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;

    margin-top: 10px;
}

.info_info2 {
    width: 50%;
    color: white;
    font-family: 'Roboto';
    font-weight: 300;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 15px;
}

.video {
    width: 1000px;
    height: 550px;
    align-self: center;
    justify-self: center;
    margin-top: 20px;
    padding-top: 0;
  }
  
  .videoContainer {
    display: flex;
    align-items: flex-center;
    justify-content: center;
    margin-top: 0;
    padding-top: 0;
    height: 550px;
    width: 1000px;

    
  }
  

  .superContainer {
    align-self: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0);
  }



@media screen and (max-width: 1000px) {
    .info_info2 {
        margin-left: 1px;
        margin-right: 1px;
        width: 90%;

    }

    .video {
        width: 450px;
        height: 300px;
        align-self: center;
        justify-self: center;
        margin-top: 20px;
        padding-top: 0;
      }

      .videoContainer {
        display: flex;
        align-items: flex-center;
        justify-content: center;
        margin-top: 0;
        padding-top: 0;
        height: 300px;
        width: 450px;
      }
    
        

    .informacoes {
        height: 1500px;
    }
}

@media screen and (max-width: 800px) {
  .info_info2 {
      margin-left: 1px;
      margin-right: 1px;
      width: 90%;

  }

  .video {
      width: 450px;
      height: 300px;
      align-self: center;
      justify-self: center;
      margin-top: 20px;
      padding-top: 0;
    }

    .videoContainer {
      display: flex;
      align-items: flex-center;
      justify-content: center;
      margin-top: 0;
      padding-top: 0;
      height: 300px;
      width: 450px;
    }
  
      

  .informacoes {
      height: 3000px;
  }
}

@media screen and (max-width: 500px) {
    .info_info2 {
        margin-left: 1px;
        margin-right: 1px;
        width: 90%;

    }

    .video {
        width: 400px;
        height: 200px;
        align-self: center;
        justify-self: center;
        margin-top: 20px;
        padding-top: 0;
      }

      .videoContainer {
        display: flex;
        align-items: flex-center;
        justify-content: center;
        margin-top: 0;
        padding-top: 0;
        height: 300px;
        width: 400px;
      }
    
        

    .informacoes {
        height: 2000px;
    }
}


@media screen and (max-width: 360px) {
  .info_info2 {
      margin-left: 1px;
      margin-right: 1px;
      width: 90%;

  }

  .video {
      width: 340px;
      height: 200px;
      align-self: center;
      justify-self: center;
      margin-top: 20px;
      padding-top: 0;
    }

    .videoContainer {
      display: flex;
      align-items: flex-center;
      justify-content: center;
      margin-top: 0;
      padding-top: 0;
      height: 300px;
      width: 340px;
    }
  
      

  .informacoes {
      height: 2700px;
  }
}

@media screen and (max-width: 300px) {
  .info_info2 {
      margin-left: 1px;
      margin-right: 1px;
      width: 90%;

  }

  .video {
      width: 200px;
      height: 200px;
      align-self: center;
      justify-self: center;
      margin-top: 20px;
      padding-top: 0;
    }

    .videoContainer {
      display: flex;
      align-items: flex-center;
      justify-content: center;
      margin-top: 0;
      padding-top: 0;
      height: 300px;
      width: 200px;
    }
  
      

  .informacoes {
      height: 2700px;
  }
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .hero-container2 {
    background: url(/static/media/Hero.d91f2b5b.jpg) center center/cover no-repeat; 
    height: 170vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container2 > h1 {
    color: #fff;
    font-size: 60px;
    margin-top: 50px;
  }
  
  .hero-container2 > p {
    margin-top: 2px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }

  .marger {
    color: white;
    font-size: 20px;
    margin-top: 4vh;
    margin-bottom: 4vh;
    background-color: rgba(0, 0, 0, 0.213);
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  .uploadImageIcon {
    height: 100px;
    width: 100px;
}


.cardimage {
  height: 300px;
  width: 400px;
}

.formtext3 {
    margin-top: 1vh;
    font-size: 15px;
    color: white;
}

.formDescription {
  width: 400px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.1vw;
  font-size: 15px;
  font-family: 'Quicksand';
  outline:none;
  margin-bottom: 2vh;
}

.uploadImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1vw;
  outline:none;
}

.inputbutton:hover {
  background-color: rgb(221, 221, 221);
  cursor: pointer;
}


.inputfile {
  z-index: -1;
  position: absolute;
}

.inputbutton {

  height: 300px;
  width: 400px;
  background-color: rgb(255, 255, 255);
  border-radius: 0.2vw;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}


.form {
    height: 30px;
    width: 400px;
    background: white;
    border: 1px rgba(0, 0, 0, 0.212) solid;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    outline:none;
    font-weight: bold;
    font-family: 'Quicksand';
    margin-bottom: 10px;

}
  
.formtext {
    align-self: center;
    font-size: 20px;
    color: white;
}

.sim {

  font-size: 20px;
  color: white;
  margin-right: 100px;
  margin-left: 10px;
}

.nao {

  font-size: 20px;
  color: white;
  margin-right: 100px;
  margin-left: 10px;
}

.centralizer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.columner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1vw;
  margin-right: 1vw;
  width: 30vw;
  text-align: center;
}

.recadao {
  font-size: 15px;
  color: white;
  text-align: center;
  

}

.radio_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
  margin-left: 7vw;
}

.selector1 {
  height: 10px;
  width: 400px;
  margin-bottom: 40px;
}

.havecount {

  margin-top: 0vw;
  margin-bottom: -20px;
   
  background-color: rgba(0, 0, 0, 0.104);
  height: 20px;
  color: rgb(255, 255, 255);
}


.havecount:hover {

  color: rgb(128, 126, 126);
}


.moredescription {
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;

}


.message {
  font-size: 2vw;

}

.button {
    height: 3vw;
    width: 7vw;
    align-self: center;
    font-size: 1.3vw;
    border: 0.1vw rgba(128, 128, 128, 0.465) solid;
    border-radius: 0.3vw;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
}

.button:hover {
    background: lightgray;
}

.titlecontato {
    font-size: 2.5vw;
    margin-top: 2vw;
    margin-bottom: 0;
    font-family: 'Cabin';
  }

.info_column_register_popp {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  text-align: center;
  margin: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-bottom: 40px;
}

.kinger_text {
  text-align: center;
  width: 70vw;
  font-size: 10px;
}

  
  @media screen and (max-width: 1400px) {
    .hero-container2 {
      height: 370vh;
    }
    .hero-container2 > h1 {
      font-size: 70px;
      color: white;
    }
    .hero-container2 > p {
      font-size: 15px;
      margin-bottom: 5vh;
      color: white;
    }

    .centralizer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .columner {
      width: 60vw;
    }
  }
  
  @media screen and (max-width: 768px) {

    .hero-container2 {
      height: 470vh;
    }

    .hero-container2 > h1 {
      font-size: 50px;
    }
  
    .hero-container2 > p {
      font-size: 20px;
      
      
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }

    .centralizer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 400px) {
    
.form {
  height: 30px;
  width: 300px;
  background: white;
  border: 1px rgba(0, 0, 0, 0.212) solid;
  border-radius: 5px;
  font-size: 15px;
  padding-left: 10px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  outline:none;
  font-weight: bold;
  font-family: 'Quicksand';
  margin-bottom: 10px;

}

.selector1 {
  width: 300px;
}

.formDescription {
  width: 300px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.1vw;
  font-size: 15px;
  font-family: 'Quicksand';
  outline:none;
  margin-bottom: 2vh;
}

.inputbutton {
  width: 300px;
  height: 200px;
}

    
  }
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary22 {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    margin-top: -30px;
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
.form_modified {
    height: 30px;
    width: 400px;
    background: white;
    border: 1px rgba(0, 0, 0, 0.212) solid;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    outline:none;
    font-weight: bold;
    font-family: 'Quicksand';
    margin-bottom: 10px;
    margin-top: 5vh;

}

.confirmer {
    margin-top: 5vh;
    width: 11vw;
    height: 4vh;
}

@media only screen and (max-width: 760px) {
    .confirmer {
        width: 100px;
        height: 50px;
    }

}


  
  .hero-container3 {
    background: url(/static/media/Hero.d91f2b5b.jpg) center center/cover no-repeat; 
    height: 330vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container3 > h1 {
    color: #fff;
    font-size: 60px;
    margin-top: 50px;
  }
  
  .hero-container3 > p {
    margin-top: 2px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  @media screen and (max-width: 1200px) {

    .hero-container3 {
      height: 450vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 900px) {

    .hero-container3 {
      height: 420vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 768px) {

    .hero-container3 {
      height: 470vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 430px) {

    .hero-container3 {
      height: 450vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }
