video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .hero-container2 {
    background: url('./Hero.jpg') center center/cover no-repeat; 
    height: 170vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container2 > h1 {
    color: #fff;
    font-size: 60px;
    margin-top: 50px;
  }
  
  .hero-container2 > p {
    margin-top: 2px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }

  .marger {
    color: white;
    font-size: 20px;
    margin-top: 4vh;
    margin-bottom: 4vh;
    background-color: rgba(0, 0, 0, 0.213);
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  .uploadImageIcon {
    height: 100px;
    width: 100px;
}


.cardimage {
  height: 300px;
  width: 400px;
}

.formtext3 {
    margin-top: 1vh;
    font-size: 15px;
    color: white;
}

.formDescription {
  width: 400px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.1vw;
  font-size: 15px;
  font-family: 'Quicksand';
  outline:none;
  margin-bottom: 2vh;
}

  @import url('https://fonts.googleapis.com/css2?family=Cabin&family=Dosis:wght@500&family=Quicksand:wght@400;600&display=swap');

.uploadImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1vw;
  outline:none;
}

.inputbutton:hover {
  background-color: rgb(221, 221, 221);
  cursor: pointer;
}


.inputfile {
  z-index: -1;
  position: absolute;
}

.inputbutton {

  height: 300px;
  width: 400px;
  background-color: rgb(255, 255, 255);
  border-radius: 0.2vw;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}


.form {
    height: 30px;
    width: 400px;
    background: white;
    border: 1px rgba(0, 0, 0, 0.212) solid;
    border-radius: 5px;
    font-size: 15px;
    padding-left: 10px;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    outline:none;
    font-weight: bold;
    font-family: 'Quicksand';
    margin-bottom: 10px;

}
  
.formtext {
    align-self: center;
    font-size: 20px;
    color: white;
}

.sim {

  font-size: 20px;
  color: white;
  margin-right: 100px;
  margin-left: 10px;
}

.nao {

  font-size: 20px;
  color: white;
  margin-right: 100px;
  margin-left: 10px;
}

.centralizer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.columner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1vw;
  margin-right: 1vw;
  width: 30vw;
  text-align: center;
}

.recadao {
  font-size: 15px;
  color: white;
  text-align: center;
  

}

.radio_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
  margin-left: 7vw;
}

.selector1 {
  height: 10px;
  width: 400px;
  margin-bottom: 40px;
}

.havecount {

  margin-top: 0vw;
  margin-bottom: -20px;
   
  background-color: rgba(0, 0, 0, 0.104);
  height: 20px;
  color: rgb(255, 255, 255);
}


.havecount:hover {

  color: rgb(128, 126, 126);
}


.moredescription {
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;

}


.message {
  font-size: 2vw;

}

.button {
    height: 3vw;
    width: 7vw;
    align-self: center;
    font-size: 1.3vw;
    border: 0.1vw rgba(128, 128, 128, 0.465) solid;
    border-radius: 0.3vw;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
}

.button:hover {
    background: lightgray;
}

.titlecontato {
    font-size: 2.5vw;
    margin-top: 2vw;
    margin-bottom: 0;
    font-family: 'Cabin';
  }

.info_column_register_popp {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  text-align: center;
  margin: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-bottom: 40px;
}

.kinger_text {
  text-align: center;
  width: 70vw;
  font-size: 10px;
}

  
  @media screen and (max-width: 1400px) {
    .hero-container2 {
      height: 370vh;
    }
    .hero-container2 > h1 {
      font-size: 70px;
      color: white;
    }
    .hero-container2 > p {
      font-size: 15px;
      margin-bottom: 5vh;
      color: white;
    }

    .centralizer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .columner {
      width: 60vw;
    }
  }
  
  @media screen and (max-width: 768px) {

    .hero-container2 {
      height: 470vh;
    }

    .hero-container2 > h1 {
      font-size: 50px;
    }
  
    .hero-container2 > p {
      font-size: 20px;
      
      
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }

    .centralizer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 400px) {
    
.form {
  height: 30px;
  width: 300px;
  background: white;
  border: 1px rgba(0, 0, 0, 0.212) solid;
  border-radius: 5px;
  font-size: 15px;
  padding-left: 10px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  outline:none;
  font-weight: bold;
  font-family: 'Quicksand';
  margin-bottom: 10px;

}

.selector1 {
  width: 300px;
}

.formDescription {
  width: 300px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.1vw;
  font-size: 15px;
  font-family: 'Quicksand';
  outline:none;
  margin-bottom: 2vh;
}

.inputbutton {
  width: 300px;
  height: 200px;
}

    
  }