.cardBackgrounder {
    background-color: rgba(0, 0, 0, 0.597);
    display: flex;
    position: absolute;
    align-self: flex-start;
    display: none;
    position: fixed;
    z-index: 999;
}

.genDiv {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0vw;
    top: 0vw;
    right: 0vw;
    left: 0vw;
    z-index: 10000;


}

.modal {
    position: fixed;
    bottom: 0vw;
    top: 0vw;
    right: 0vw;
    left: 0vw;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.672);
    
  }
  
  .modal_content {
    background-color: white;
    position: absolute;
    width: 750px;
    height: 400px;
    padding: 20px;
    border-radius: 15px;
    border: 2px solid rgb(0, 0, 0);
    z-index: 1000;
    display: flex;
    padding-right: 0;
  }

  .imagedescription {
      display: flex;
      flex-direction: column;
      width: 60%;
      height: 100%;
      border-radius: 1vw;

  }

  .butoneiro {
      width: 200px;
      height: 40px;
      font-family: 'Roboto';
      margin-top: 1.8vh;
      border: 1px solid black;
      border-radius: 3px;
      text-align: center;
      color: black;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5vh;

      background-color: rgba(194, 194, 194, 0.816);
  }

  .butoneiro:hover {
    background-color: rgb(232, 232, 232);
    cursor: pointer;
}

.project_description_master {
    overflow: hidden;
    width: 190%;
    margin-left: 0.3vw;
    margin-top: 0.5vh;
}

  .fount {
      margin-top: 1vh;
      font-size: 15px;
      font-family: 'Roboto'
  }

  .maxfount {
      width: 155%;
      overflow: hidden;


  }

  .campi_ensino {
    width: 30%;
    height: 16%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-self: flex-end;
    justify-content: center;
  }

  .campi_ensino_text {
    font-size: 14px;
    display: flex;
  }


  .exit {
      display: flex;
      position: absolute;
      width: 3%;
      height: 5%;
      margin-left: 91%;
  }

  .exit:hover {
    background-color: rgba(160, 160, 167, 0.241);
    cursor: pointer;
}

  .fount_part {
    margin-top: 1vh;
    font-size: 12px;
    font-family: 'Roboto';
    width: 100%;
    overflow: hidden;
}

  .popupimage {
      width: 102%;
      height: 62%;
      border-radius: 1vw;
  }

  .info_column {
      display: flex;
      flex-direction: column;
      margin-left: 3%;
      margin-top: 2%;
      height: 100%;
      width: 70%;

  }
  .project_title {
      font-size: 13px;
      margin-top: 4%;
      width: 130%;

  }

  .category-color {
    background-color: rgb(123, 114, 114);
    height: 15%;
    display: flex;
    align-self: flex-end;
    border-radius: 5px 0px 0px 5px;
    border: 1px 1px 1px 0px solid black;
    align-items: center;
    justify-content: center;

  }

  .category-text {
      color: white;
      font-family: 'Roboto';
      text-align: center;
  }

  .instagram {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .facebook {
    width: 25px;
    height: 25px;
    margin-left: 0px;
  }


  .twitter {
    width: 25px;
    height: 25px;
    margin-left: 10px;

  }

  .social_media_div {
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 73%;
      margin-top: -0.4%;

  }

  .social_media_div:hover {
    cursor: pointer;

}

@media only screen and (max-width: 760px) {
    .modal_content {
        background-color: white;
        position: absolute;
        width: 400px;
        height: 600px;
        padding: 10px;
        border-radius: 15px;
        border: 2px solid rgb(0, 0, 0);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        padding-right: 0;
      }

    
    .campi_ensino {
      width: 35%;
      height: 30%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-self: flex-end;
      justify-content: center;
    }
 

    .imagedescription {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60%;
        border-radius: 1vw;
        margin-top: -20px;
        text-align: center;
        margin-left: 0vw;
  
    }

    .info_column {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin-left: 0vw;
  
    }

    .genDiv {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        bottom: 0vw;
        top: 0vw;
        right: 0vw;
        left: 0vw;
        z-index: 10000;
    
    
    }

    .popupimage {
        width: 50%;
        height: 70%;
        border-radius: 1vw;
    }

    .project_description_master {
        overflow: hidden;
        width: 100%;
        font-size: 13px;
        margin-left: 0.3vw;
        margin-top: 0.5vh;
    }

    .maxfount {
        width: 100%;
        overflow: hidden;
  
  
    }

    .social_media_div {
        display: flex;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        margin-top: 137.5%;
  
    }

    .instagram {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
      }
    
      .facebook {
        width: 30px;
        height: 30px;
      }
    
    
      .twitter {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
    
      }
    
    .project_title {
      font-size: 12px;
      margin-top: 4%;
      width: 100%;
    }

      .exit {
        display: flex;
        position: absolute;
        width: 25px;
        height: 25px;
        margin-left: 88.5%;
    }

    .category-text {
      color: white;
      font-family: 'Roboto';
      font-size: 13px;
      text-align: center;
    } 
    .category-color {
      background-color: rgb(123, 114, 114);
      height: 15%;
      display: flex;
      align-self: flex-end;
      border-radius: 5px 0px 0px 5px;
      border: 1px 1px 1px 0px solid black;
      align-items: center;
      justify-content: center;
  
    }

    .butoneiro {
      width: 150px;
      height: 30px;
      font-family: 'Roboto';
      margin-top: 1.8vh;
      border: 1px solid black;
      border-radius: 3px;
      text-align: center;
      color: black;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5vh;

      background-color: rgba(194, 194, 194, 0.816);
  }

    .testador {
      font-size: 10px;



    }

    .project_description_master {
      overflow: hidden;
      width: 100%;
      height: 50px;
      font-size: 13px;
      margin-left: 0.3vw;
      margin-top: 0.5vh;
  }


    
}

@media only screen and (max-width: 500px) {
    .modal_content {
        background-color: white;
        position: absolute;
        width: 300px;
        height: 600px;
        padding: 10px;
        border-radius: 15px;
        border: 2px solid rgb(0, 0, 0);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        padding-right: 0;
      }


      

      .campi_ensino {
        width: 35%;
        height: 20%;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-self: flex-end;
        justify-content: center;
      }
    
      .campi_ensino_text {
        font-size: 12px;
        display: flex;
      }


    .imagedescription {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 400px;
        border-radius: 1vw;
        margin-top: -20px;
        text-align: center;
        margin-left: 0vw;
  
    }

    .info_column {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin-left: 0vw;
  
    }

    .genDiv {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        bottom: 0vw;
        top: 0vw;
        right: 0vw;
        left: 0vw;
        z-index: 10000;
    
    
    }

    .popupimage {
        width: 200px;
        height: 100px;
        border-radius: 1vw;
    }

    .project_description_master {
        display: flex;
        height: 70px;
        font-size: 13px;
        margin-left: 0.3vw;
    }

    .maxfount {
        width: 100%;
        overflow: hidden;
  
  
    }

    .social_media_div {
        display: flex;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 33%;
        margin-top: 130%;
  
    }

    .instagram {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
      }
    
      .facebook {
        width: 30px;
        height: 30px;
      }
    
    
      .twitter {
        width: 25px;
        height: 25px;
        margin-left: 1vw;
    
      }

      .exit {
        display: flex;
        position: absolute;
        width: 20px;
        height: 20px;
        margin-left: 86.5%;
    }

    .category-text {
      color: white;
      font-family: 'Roboto';
      font-size: 13px;
      text-align: center;
    } 
    .category-color {
      background-color: rgb(123, 114, 114);
      height: 15%;
      display: flex;
      align-self: flex-end;
      border-radius: 5px 0px 0px 5px;
      border: 1px 1px 1px 0px solid black;
      align-items: center;
      justify-content: center;
  
    }
}