.informacoes {
    display: flex;
    flex-direction: column;
    background-color: rgb(33, 33, 33);
    width: 100%;
    height: 1700px;
    align-items: center;

    
}

.info_title {
    color: white;
    font-size: 40px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 50px;

    
}

.info_info {
    color: white;
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 300;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;

    margin-top: 10px;
}

.info_info2 {
    width: 50%;
    color: white;
    font-family: 'Roboto';
    font-weight: 300;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 15px;
}

.video {
    width: 1000px;
    height: 550px;
    align-self: center;
    justify-self: center;
    margin-top: 20px;
    padding-top: 0;
  }
  
  .videoContainer {
    display: flex;
    align-items: flex-center;
    justify-content: center;
    margin-top: 0;
    padding-top: 0;
    height: 550px;
    width: 1000px;

    
  }
  

  .superContainer {
    align-self: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0);
  }



@media screen and (max-width: 1000px) {
    .info_info2 {
        margin-left: 1px;
        margin-right: 1px;
        width: 90%;

    }

    .video {
        width: 450px;
        height: 300px;
        align-self: center;
        justify-self: center;
        margin-top: 20px;
        padding-top: 0;
      }

      .videoContainer {
        display: flex;
        align-items: flex-center;
        justify-content: center;
        margin-top: 0;
        padding-top: 0;
        height: 300px;
        width: 450px;
      }
    
        

    .informacoes {
        height: 1500px;
    }
}

@media screen and (max-width: 800px) {
  .info_info2 {
      margin-left: 1px;
      margin-right: 1px;
      width: 90%;

  }

  .video {
      width: 450px;
      height: 300px;
      align-self: center;
      justify-self: center;
      margin-top: 20px;
      padding-top: 0;
    }

    .videoContainer {
      display: flex;
      align-items: flex-center;
      justify-content: center;
      margin-top: 0;
      padding-top: 0;
      height: 300px;
      width: 450px;
    }
  
      

  .informacoes {
      height: 3000px;
  }
}

@media screen and (max-width: 500px) {
    .info_info2 {
        margin-left: 1px;
        margin-right: 1px;
        width: 90%;

    }

    .video {
        width: 400px;
        height: 200px;
        align-self: center;
        justify-self: center;
        margin-top: 20px;
        padding-top: 0;
      }

      .videoContainer {
        display: flex;
        align-items: flex-center;
        justify-content: center;
        margin-top: 0;
        padding-top: 0;
        height: 300px;
        width: 400px;
      }
    
        

    .informacoes {
        height: 2000px;
    }
}


@media screen and (max-width: 360px) {
  .info_info2 {
      margin-left: 1px;
      margin-right: 1px;
      width: 90%;

  }

  .video {
      width: 340px;
      height: 200px;
      align-self: center;
      justify-self: center;
      margin-top: 20px;
      padding-top: 0;
    }

    .videoContainer {
      display: flex;
      align-items: flex-center;
      justify-content: center;
      margin-top: 0;
      padding-top: 0;
      height: 300px;
      width: 340px;
    }
  
      

  .informacoes {
      height: 2700px;
  }
}

@media screen and (max-width: 300px) {
  .info_info2 {
      margin-left: 1px;
      margin-right: 1px;
      width: 90%;

  }

  .video {
      width: 200px;
      height: 200px;
      align-self: center;
      justify-self: center;
      margin-top: 20px;
      padding-top: 0;
    }

    .videoContainer {
      display: flex;
      align-items: flex-center;
      justify-content: center;
      margin-top: 0;
      padding-top: 0;
      height: 300px;
      width: 200px;
    }
  
      

  .informacoes {
      height: 2700px;
  }
}