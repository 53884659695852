
  
  .hero-container3 {
    background: url('./Hero.jpg') center center/cover no-repeat; 
    height: 330vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container3 > h1 {
    color: #fff;
    font-size: 60px;
    margin-top: 50px;
  }
  
  .hero-container3 > p {
    margin-top: 2px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  @media screen and (max-width: 1200px) {

    .hero-container3 {
      height: 450vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 900px) {

    .hero-container3 {
      height: 420vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 768px) {

    .hero-container3 {
      height: 470vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }

  @media screen and (max-width: 430px) {

    .hero-container3 {
      height: 450vh;
    }

    .hero-container3 > h1 {
      font-size: 50px;
    }
  
    .hero-container3 > p {
      font-size: 20px;
    }

  }